<template>
	<common-table
			:itemScope="['robotstatus']"
			:headers="headers"
			:listData="listData"
			@changePage="changePage"
			:loading="loading"
			:btnAction="true"
			@search="search"
			@remove="remove"
			@save="save"
			@edit="edit"
			ref="ct"
			:leftConfig="leftConfig"
			:rightConfig="rightConfig"
			:listDeploy="listDeploy"
			pageTitle="机器人通知"
			:btnActionEvent="btnActionEvent"
			@otherTableOperate="otherTableOperate"
	>
		<template v-slot:robotstatus="{ item }">
			<w-switches
					inset
					label=""
					color="var(--v-primary-base)"
					@change="updateStatus(item.sqlid)"
					v-model="item.robotstatus" hide-details dense/>
		</template>
	</common-table>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/systemManage/notice/app-notice.js';

export default {
	mixins: [commonCurd],
	data() {
		return {
			typeItems: [{code: '1', desc: '微信'}, {code: '2', desc: '钉钉'}],
			api,
			btnActionEvent: [
				{
					event: 'editItem',
					icon: 'bianji2-fill',
					color: 'primary',
					tooltip: '编辑'
				},
				{
					event: 'test',
					icon: 'ceshi-fill',
					color: 'primary',
					tooltip: '测试通知'
				},
				{
					event: 'deleteItem',
					icon: 'shanchu-fill',
					color: 'var(--v-redness-base)',
					tooltip: '删除'
				}
			],
			leftConfig: [
				{type: 'select', label: '选择类型', property: 'type', items: []},
				{type: 'select', label: '选择大组', property: 'group', items: []}
			],
			rightConfig: [
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			headers: [
				{text: '类型', value: 'type'},
				{text: '大组', value: 'group'},
				{text: '代码', value: 'code'},
				{text: '描述', value: 'robotname'},
				{text: '状态', value: 'robotstatus'},
				{text: '操作', value: 'actions', width: 120}
			],
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'type',
						connector: {
							props: {
								required: true,
								label: "选择类型"
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'group',
						connector: {
							props: {
								required: true,
								label: "选择大组"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'robotname',
						connector: {
							props: {
								required: true,
								label: "通知描述"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'url',
						connector: {
							props: {
								required: false,
								label: "机器人地址"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'secrect',
						connector: {
							props: {
								required: false,
								label: "机器人密钥"
							}
						}
					},
					{
						cols: 6,
						subassembly: "WSwitches",
						disabledDeleteDefault: true,
						property: 'robotstatus',
						connector: {
							props: {
								label: '状态',
								inset: true,
								dense: true
							}
						},
						default: true
					}
				]
			}
		}
	},
	methods: {
		otherTableOperate(event, item) {
			if (event === this.btnActionEvent[1].event) {
				this.axios.post(api.test, {sqlid: item.sqlid}).then((res) => {
					if (res.code === this.staticVal.Code.Success) {
						this.snackbar.success(res.msg);
					}
				})
			}
		}
	},
	mounted() {
		this.axios.post(this.select_data, {keyname: 'robotgroup'}).then((res) => {
			if (res.code === this.staticVal.Code.Success) {
				this.leftConfig[1].items = res.data[0].values
				this.leftConfig[0].items = this.typeItems
				this.listDeploy.row[0].connector.props.items = this.typeItems
				this.listDeploy.row[1].connector.props.items = res.data[0].values
			}
		})
	}
}
</script>

<style scoped lang="scss">

</style>
